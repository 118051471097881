<template>
  <div class="Confirm">
    <div class="Confirm__dialog-content">
      <div class="Confirm__title">{{ title }}</div>
      <div class="Confirm__dialog-body">{{ content }}</div>
      <div class="Confirm__footer">
        <button class="button button-danger" type="text" @click="cancel()">
          {{ no }}
        </button>
        <button class="button button-success" type="text" @click="confirm()">
          {{ yes }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    yes: {
      type: String,
      default: 'Yes',
    },
    no: {
      type: String,
      default: 'No',
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/common/navigation/Confirm.scss';
</style>
